<!--
 * @Description: 班级名称评价记录
 * @Author: xiawenlong
 * @Date: 2021-04-28 18:21:09
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-05-30 15:23:59
-->
<template>
  <div class="class-study-info">
    <table-list
      title="班级名称评价记录"
      :loading="loading"
      :data="recordList"
      :search-form="searchForm(this)"
      :btns="btns(this)"
      :columns="columns(this)"
      :pager="pager"
      :options="{ selection: true, operatesWidth: 200 }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>

<script>
// 搜索框配置
const searchForm = () => [
  {
    label: '员工名称',
    prop: 'userName',
  },
  {
    label: '员工手机号',
    prop: 'mobile',
  },
  {
    label: '评价星际',
    type: 'select',
    width: 120,
    prop: 'stars',
    children: [
      { value: 5, label: '五星' },
      { value: 4, label: '四星' },
      { value: 3, label: '三星' },
      { value: 2, label: '二星' },
      { value: 1, label: '一星' },
      { value: 0, label: '全部' },
    ],
  },
]
const btns = _this => [
  {
    label: '导出评价记录',
    method: _this.handleAddByLibrary,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工名称',
    minWidth: 130,
  },
  {
    prop: 'mobile',
    minWidth: 130,
    label: '手机号',
  },
  {
    prop: 'cardNo',
    label: '证件号码',
    width: '200',
  },
  {
    prop: 'evaluateStars',
    label: '评价星级',
    width: '120',
    formatter: row => {
      return ['', '一星', '二星', '三星', '四星', '五星'][row.evaluateStars]
    },
  },
  {
    prop: 'evaluateContent',
    label: '评价内容',
    minWidth: '400',
  },
  {
    prop: 'createTime',
    label: '评价时间',
    minWidth: 180,
  },
]
import TableList from '@/components/TableList'
import { getEvaluateRecordList, evaluateExport } from '@/api/class'
import to from 'await-to'
export default {
  name: 'ClassEvaluate',
  components: {
    TableList,
  },
  data() {
    return {
      searchForm,
      btns,
      columns,
      loading: false,
      recordList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      select: [],
    }
  },
  mounted() {
    this.getEvaluateRecordListData()
  },
  methods: {
    async getEvaluateRecordListData() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classId } = this.$route.params
      this.loading = true
      const [res, err] = await to(
        getEvaluateRecordList({ classId, current, size, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.recordList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getEvaluateRecordListData()
    },
    async handleAddByLibrary() {
      let arr = []
      if (this.select.length) this.select.map(v => arr.push(v.classEvaluateId))
      const { classId } = this.$route.params
      const [res, err] = await to(evaluateExport({ classId, ids: arr, ...this.queryParams }))
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '班级评价记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
      // this.$router.push(`/class/addCourse/${classId}/library`)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getEvaluateRecordListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getEvaluateRecordListData()
    },
    handleSelectionChange(val) {
      this.select = val
    },
  },
}
</script>
<style lang="scss" scoped>
.class-add-course {
  .subTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #4d4e5d;
    margin-bottom: 12px;
  }
}
</style>
